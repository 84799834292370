<script setup>
  import { ref, computed, defineProps, reactive, watchEffect, watch } from "vue";
  import { createPayLog, alipayForm } from '../api/index'
  import { ElMessage, ElLoading } from 'element-plus'
  import { useRouter } from "vue-router";
  import { useStore } from 'vuex'
  import { invoice, packageList } from "../api/company";
  import { ssoInfo, systemConfig, writeReason } from "../api/index"
  import { formatDate } from '../assets/js/date';
  import { removeCookie, getCookie } from '../assets/js/cookie';
  import Bus from "../js/Bus";
  import axios from 'axios';
  import { afterApply, correctionList, setSubmitWay } from "../api/aftersale";
  import { debounce } from '../utils/index'
  const router = useRouter()
  const store = useStore()
  const formRef = ref(null);
  const formRef1 = ref(null)
  const formLabelWidth = '80px'
  const payType = ref(0)
  const chargeType = ref(0)
  const receiveType = ref(1)
  const invoiceType = ref(0)
  const totalPrice = ref(0)
  const limitPrice = ref(0)
  const list = reactive({
    data: []
  })
  const form = reactive({
    money: null,
    packageId: 0,
    deductionAmount: null,
    ques: '',
  })
  const validatorMoney = (rule, value, callback) => {
    if (value <= 0) {
      return callback(new Error('金额需大于0'))
    }
    callback()
  };
  const formRules = {
    money: [
      { required: true, message: '金额不能为空', trigger: 'blur' },
      { validator: validatorMoney, trigger: 'blur' }
    ],
    ques: [{ required: true, message: '退款原因不能为空', trigger: 'blur' }],
    companyName: [{ required: true, message: '企业名称不能为空', trigger: 'blur' }],
    creditCode: [{ required: true, message: '企业税号不能为空', trigger: 'blur' }],
    bankName: [{ required: true, message: '开户行不能为空', trigger: 'blur' }],
    bankNumber: [{ required: true, message: '银行账户不能为空', trigger: 'blur' }],
    registerAddress: [{ required: true, message: '企业地址不能为空', trigger: 'blur' }],
    registerPhone: [{ required: true, message: '电话号码不能为空', trigger: 'blur' }],
    address: [{ required: true, message: '接收地址不能为空', trigger: 'blur' }],
    mail: [{ required: true, message: '电子邮箱地址不能为空', trigger: 'blur' }],
    receiveName: [{ required: true, message: '收件人不能为空', trigger: 'blur' }],
    receivePhone: [{ required: true, message: '联系电话不能为空', trigger: 'blur' }],
    personName: [{ required: true, message: '发票抬头不能为空', trigger: 'blur' }],
    personAddress: [{ required: true, message: '接收地址不能为空', trigger: 'blur' }],
    personMail: [{ required: true, message: '电子邮箱地址不能为空', trigger: 'blur' }],
    personReceiveName: [{ required: true, message: '收件人不能为空', trigger: 'blur' }],
    personReceivePhone: [{ required: true, message: '联系电话不能为空', trigger: 'blur' }],
    fileName: [{ required: true, message: '请上传文件', trigger: 'blur' }],
    fileUrls: [{ required: true, message: '请上传截图', trigger: 'blur' }],
    fileUrls1: [{ required: true, message: '请上传截图', trigger: 'blur' }],
    correctionNoticeTime: [{ required: true, message: '请选择下补正通知时间', trigger: 'blur' }],
    reasonIds: [{ required: true, message: '请选择补正原因', trigger: 'blur' }],
    applyReason: [{ required: true, message: '请填写补充说明', trigger: 'blur' }],
    applyReason1: [{ required: true, message: '请填写补充说明', trigger: 'blur' }],
  }
  const payLogId = computed({
    get () {
      return store.state.payLogId
    },
    set (val) {
      store.commit('updatePayLogId', val)
    }
  })
  const form1 = computed({
    get () {
      return store.state.invoice
    },
    set (val) {
      store.commit('updateInvoice', val)
    }
  })
  const show = computed({
    get () {
      return store.state.showAuth
    },
    set (val) {
      store.commit('updateAuth', val)
    }
  })
  const amount = computed({
    get () {
      return store.state.amount
    },
    set (val) {
      store.commit('updateAmount', val)
    }
  })
  const userInfo = computed({
    get () {
      return store.state.userInfo
    },
    set (val) {
      store.commit('updateUserInfo', val)
    }
  })
  const type = computed({
    get () {
      return store.state.type
    },
    set (val) {
      store.commit('authType', val)
    }
  })
  const changeType1 = computed({
    get () {
      return store.state.chargeType
    },
    set (val) {
      store.commit('updateChargeType', val)
    }
  })
  // const props = defineProps({
  //   show: {
  //     type: Boolean,
  //     default: false,
  //     required: true
  //   }
  // });
  // const emit = defineEmits(['sbumit', 'update:show'])
  const goAliPay = (res) => {
    // this.$router.replace({
    //   path: '/subscribe',
    //   query: {
    //     out_trade_no: res.data.payLog.orderNo,
    //     type: 0
    //   }
    // })
    document.body.removeChild(document.getElementById('pay-box'))
  }
  
  const beforePay = debounce(() => {
    // const loading = ElLoading.service({
    //   lock: true,
    //   text: '订单生成中...',
    //   background: 'rgba(0, 0, 0, 0.7)',
    // })
    Bus.$emit('payLoad', true)
    let url = window.location.href
    if (url.includes('&charset')) {
      url = url.split('&charset')[0]
    }
    if (url.includes('?charset')) {
      url = url.split('?charset')[0]
    }
    let params = {
      amount: form.money
    }
    if (chargeType.value === 1) {
      params.packageId = form.packageId
      params.deductionAmount = form.deductionAmount
    }
    createPayLog(params).then(ret => {
      buttonLoading.value = false
      if (ret.data) { 
        if (payType.value === 0) {
          alipayForm({
            payLogId: ret.data.id,
            returnUrl: url
          }).then(res => {
            const div = document.createElement('div');
            div.id = 'pay-box'
            div.innerHTML = res.data.form;
            document.body.appendChild(div);
            document.getElementById('pay-box').children[0].submit();
            // loading.close()
            goAliPay(res)
          })
        } else {
          // console.log('微信支付');
        } 
      } else {
        //余额支付
        getUserInfo()
        ElMessage.success('购买成功')
        // loading.close()
        Bus.$emit('payLoad', false)
      }

    }).catch(err => {
      // loading.close()
      Bus.$emit('payLoad', false)
      buttonLoading.value = false
    })
  }, 1000)

  const buttonLoading = ref(false)

  const submit = () => {
    if (buttonLoading.value) {
      return
    }
    //0 企业认证 1认证中 2充值 3退款理由 4退款申请等待 5开票申请 6开盘申请等待 7消息通知 8 批量生成软件 9申请售后
     if (type.value === 11) { //选择套餐
      store.commit('updateAuth', false)
      Bus.$emit('choosePackageSuccess', packageEquities.value[packageEquitiesIndex.value].id)
      return
    }
    if (type.value === 10) {
      formRef4.value.validate((valid) => {
        if (!valid) return
        buttonLoading.value = true
        commitReason()
      })
      return
    }
    if (type.value === 9) {
      formRef3.value.validate((valid) => {
        // 不通过校验
        console.log(form3.correctionNoticeTime);
        if (!valid) return
        buttonLoading.value = true
        afterSaleApply()
      })
      return
    }
    if (type.value === 8) {
      formRef2.value.validate((valid) => {
        // 不通过校验
        if (!valid) return
        if (showErr.value) {
          return
        }
        buttonLoading.value = true
        uploadTemplate()
      })
      return
    }
    if (type.value === 7) {
      if (checked.value) {
        let noticeDetail = JSON.parse(localStorage.getItem('noticeDetail'))
        localStorage.setItem('noticeId', userInfo.value.id + '-' + noticeDetail.id)
      }
      store.commit('updateAuth', false)
      return
    }
    if (type.value === 2) {
      formRef.value.validate((valid) => {
        // 不通过校验
        if (!valid) return
        buttonLoading.value = true
        store.commit('updateAuth', false)
        beforePay()
      })
      return
    }
    if (type.value === 3) {
      store.commit('updateAuth', false)
      return
    }
    if (type.value === 5) {
      if (amount1.value <= 0) {
        return
      }
      formRef1.value.validate((valid) => {
        if (!valid) {
          return
        }
        buttonLoading.value = true
        doInvoice()
      })
      return
    }
    if (type.value === 4 || type.value === 6 || type.value === 1) {
      store.commit('updateAuth', false)
      return
    }
    store.commit('updateAuth', false)
    openWindow('#/auth?type=' + type.value)
  }

  const doInvoice = debounce(() => {
    let params = JSON.parse(JSON.stringify(form1.value))
    if (receiveType.value === 0) {
      delete params['mail']
    } else {
      delete params['address']
      delete params['receiveName']
      delete params['receivePhone']
    }
    if (invoiceType.value === 0) {
      delete params['bankName']
      delete params['bankNumber']
      delete params['registerAddress']
      delete params['registerPhone']
    }
    params.receiveType = receiveType.value
    params.invoiceType = invoiceType.value
    params.payLogId = payLogId.value
    params.type = companyType.value
    invoice(params).then(res => {
      ElMessage.success(res.message)
      Bus.$emit('invoiceDone', 1)
      store.commit('updateAuth', false)
    }).catch(err => {
      buttonLoading.value = false
    })
  }, 1000)

  const openWindow = (path) => { 
    let baseUrl =  window.location.origin
    let pathArr = window.location.pathname.split('/')
    pathArr.splice(pathArr.length - 1, 1, path);
    let url = baseUrl + pathArr.join('/')
    window.open(url, "_blank")
  }

  const isMuti = ref(false)
  const changePackage = item => {
    form.packageId = item.id
    let num = 100
    totalPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
    limitPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
    form.deductionAmount = null
    if (checkPackage(item).length > 1) {
      isMuti.value = true
    } else {
      isMuti.value = false
    }
  }
  const inputChange = (value, type) => {
    let result =
      ("" + value) // 第一步：转成字符串
      .replace(/[^\d^\\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    if (type === 'deductionAmount') {
      let num = 100
      if (amount.value * 1 >= totalPrice.value * 1) {
        if (result * 1 > totalPrice.value * 1) {
          result = Number(totalPrice.value)
        }
      } else {
        if (result * 1 > amount.value * 1) {
          result = Number(amount.value)
        }
      }
      limitPrice.value = (totalPrice.value * num - result * num)/num
    }
    form[type] = result
  }

  const cancel = () => {
    // emit('update:show', false)
    store.commit('updateAuth', false)
  }
  const beforeClose = (done) => {
    // emit('update:show', false)
    store.commit('updateAuth', false)
    done()
  } 
  const invoiceTypeChange = (val) => {
    if (val * 1 === 1) {
      // receiveType.value = 0
    }
  }
  const getPackage = () => {
    packageList({
      equities: equities.value
    }).then(res => {
      list.data = res.data
      if (list.data.length > 0) {
        let item = list.data[0]
        form.packageId = item.id
        let num = 100
        totalPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
        limitPrice.value = userInfo.value.isAgent ? ((item.price * num) * (userInfo.value.agentLevel.discount * num))/(num * num) : item.price
        if (checkPackage(item).length > 1) {
          isMuti.value = true
        } else {
          isMuti.value = false
        }
      }
    })
  }
  const getUserInfo = (isCreate) => {
    ssoInfo().then(res => {
      if (res) {
        store.commit('updateUserInfo', res.data)
        store.commit('updateAmount', res.data.balance)
        store.commit('updatePackageList', res.data.packageList)
      }
    })
  }

  const preview = reactive({
    data: {}
  })
  const removeHtmlTags = (str) => {
    return str.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ');
  }

  const companyType = ref(0)

  const title = ref('')

  //批量上传
  const templateLink = ref('')

  const formRef2 = ref(null)

  const form2 = reactive({
    fileName: ''
  })

  const templateFile = ref(null)

  const uploadRef = ref(null)

  
  // const uploadUrl =  process.env.VUE_APP_ENV_NAME === 'dev' ? process.env.VUE_APP_API_URL + '/write/batchCreate' : window.location.origin + process.env.VUE_APP_API_URL_PATH + '/write/batchCreate'
  const uploadUrl =  process.env.VUE_APP_ENV_NAME === 'dev' ? process.env.VUE_APP_API_URL + '/write/v2/batchCreate' : window.location.origin + process.env.VUE_APP_API_URL_PATH + '/write/v2/batchCreate'

  const token = ref('')

  const getTemp = () => {
    systemConfig('TEMPLATE_LINK').then(res => {
      console.log(res.data);
      templateLink.value = res.data
    })
  }

  const downloadUpFile = () => {
    // location.href = form2.fileName
  }
  const removeFile = () => {
    form2.fileName = ''
    showErr.value = false
  }

  const downloadTemp = () => {
    location.href = templateLink.value
  }

  const handleBeforeUpload = (file) => {
    console.log('handleBeforeUpload');
    console.log(file);
    if (file.size / 1024 / 1024 > 1) {
      ElMessage.error('文件大小不能超过 1MB!')
      return false
    }
    return true
  }

  const showErr = ref(false)
  const showErrMsg = ref('')

  const handleChange = (file) => {
    showErr.value = false
    console.log('handleChange');
    console.log(file);
    const reader = new FileReader();
    reader.onload = function(e) {
      console.log(e, e.target.result);
      
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
  
      // 读取第一个工作表
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const json_data = XLSX.utils.sheet_to_json(worksheet);
 
      // 处理 JSON 数据
      console.log('sss',json_data);
      let tempArr = json_data.filter(item => item['软件名称'])
      console.log('tempArr', tempArr);
      
      for (let i = 0; i < tempArr.length; i++) {
        const e = tempArr[i];
        console.log('xxx', e, e['软件名称'], e['软件类型（请选择）']);
        let value = e['软件名称'].toString()
        let type = e['软件类型（请选择）']
        if ((type === '嵌入式' || type === 'PC') && !(value.endsWith('软件') || value.endsWith('平台') || value.endsWith('系统'))) {
          showErr.value = true
          showErrMsg.value = '存在不合规的软件名称，全称必须要以软件、系统、平台结尾，请修改后重新上传'
          form2.fileName = file.name
          formRef2.value.validateField('fileName')
          return
        }
        if ((type === 'iOS(苹果)' || type === 'Android(安卓)') && !(value.endsWith('软件') || value.endsWith('平台') || value.endsWith('系统') || value.endsWith('客户端') || value.toString().trim().toUpperCase().endsWith('APP'))) {
          showErr.value = true
          showErrMsg.value = '存在不合规的软件名称，全称必须要以软件、系统、平台、APP、客户端结尾，请修改后重新上传'
          form2.fileName = file.name
          formRef2.value.validateField('fileName')
          return
        }
         if ((type === '小程序') && !(value.endsWith('软件') || value.endsWith('平台') || value.endsWith('系统') || value.endsWith('小程序'))) {
          showErr.value = true
          showErrMsg.value = '存在不合规的软件名称，全称必须要以软件、系统、平台、小程序结尾，请修改后重新上传'
          form2.fileName = file.name
          formRef2.value.validateField('fileName')
          return
        }
      }
      form2.fileName = file.name
      templateFile.value = file
      formRef2.value.validateField('fileName')
    };
    reader.readAsArrayBuffer(file.raw);
  }

  const handleSuccess = (res, file) => {
    console.log('handleSuccess');
    if (res.code !== 200) {
      ElMessage.error(res.message)
      if (res.code === 401) {
        store.commit('updateAuth', false)
        removeCookie('yw')
        router.push({
          path: '/login'
        })
      }
      return
    }
    store.commit('updateAuth', false)
  }

  const XLSX = require('xlsx');
  
  const uploadTemplate = debounce(() => {
    const formData = new FormData();
    formData.append('file', templateFile.value.raw); // 'file' 是后端接收文件的字段名
    formData.append('companyPackageId', companyPackageId.value);
    axios.post(uploadUrl, formData, {
      headers: {
        'Token': token.value,
        'Content-Type': 'multipart/form-data',
        'platform': 1
      }
    })
    .then(res => {
      const {code, message} = res.data
      if (code !== 200) {
        buttonLoading.value = false
        ElMessage.error(message)
        if (code === 401) {
          store.commit('updateAuth', false)
          removeCookie('yw')
          router.push({
            path: '/login'
          })
        }
        return
      }
      ElMessage.success(message)
      Bus.$emit('uploadTemplateSuccess', 1)
      store.commit('updateAuth', false)
    })
    .catch(error => {
      buttonLoading.value = false
      // console.error('文件上传失败', error);
    });
  }, 1000)
  
  //申请售后
  const writeId = computed({
    get () {
      return store.state.writeId
    },
    set (val) {
      store.commit('updateWriteId', val)
    }
  })
  const stage = computed({
    get () {
      return store.state.stage
    },
    set (val) {
      store.commit('updateStage', val)
    }
  })
  const screenshot = ref('')
  const getSS = () => {
    systemConfig('AFTER_SALE_SCREENSHOT').then(res => {
      console.log(res.data);
      screenshot.value = res.data
    })
  }

  const downloadSS = () => {
    window.open(screenshot.value, "_blank")
  }

  const uploadUrl1 = process.env.VUE_APP_ENV_NAME === 'dev' ? process.env.VUE_APP_API_URL + '/minio/upload' : window.location.origin + process.env.VUE_APP_API_URL_PATH + '/minio/upload'

  const formRef3 = ref(null)

  const isUpload = ref(false)

  const form3 = reactive({
    type: 3,
    fileUrls: '',
    fileUrls1: '',
    applyReason: '',
    applyReason1: '',
    correctionNoticeTime: '',
    reasonIds: [],
    remark: ''
  })

  const fileList = ref([])

  const afterImg = ref('')

  const beforeUpload = file => {
    let isMaxSize = file.size > 1 * 1024 * 1024
    if (isMaxSize) {
      ElMessage.warning('文件' + file.name + '太大，建议不超过1M。')
      return false
    }
    if (!(file.name).endsWith('jpg') && !(file.name).endsWith('jpeg') && !(file.name).endsWith('png')) {
      ElMessage.warning('文件' + file.name + '的文件格式不正确，请选择jpg或png')
      return false
    }
    isUpload.value = true
  }

  const handleError = (res) => {
    console.log(res);
    ElMessage.error(res.message)
    isUpload.value = false
  }

  const handleSuccess1 = (res) => {
    if (res.code !== 200) {
      isUpload.value = false
      ElMessage.error(res.message)
      if (res.code === 401) {
        removeCookie('yw')
        router.push({
          path: '/login'
        })
      }
      return
    }
    isUpload.value = false
    form3.fileUrls = res.data || ''
  }

  const beforeUpload2 = file => {
    let isMaxSize = file.size > 1 * 1024 * 1024
    if (isMaxSize) {
      ElMessage.warning('文件' + file.name + '太大，建议不超过1M。')
      return false
    }
    if (!(file.name).endsWith('jpg') && !(file.name).endsWith('jpeg') && !(file.name).endsWith('png')) {
      ElMessage.warning('文件' + file.name + '的文件格式不正确，请选择jpg或png')
      return false
    }
  }

  const handleSuccess2 = (res) => {
    if (res.code !== 200) {
      ElMessage.error(res.message)
      if (res.code === 401) {
        removeCookie('yw')
        router.push({
          path: '/login'
        })
      }
      return
    }
    console.log(res.data, fileList);
    let arr = []
    fileList.value.forEach(file => {
      console.log(file.response.data);
      arr.push(file.response.data)
    });
    form3.fileUrls1 = JSON.stringify(arr)
  }

  const afterSaleApply = debounce(() => {
    console.log(writeId.value, form3, Date.parse(new Date()));
    if (stage.value === 6) {
        setSubmitWay({
          writeId: writeId.value,
          submitWay:'manual'
        }).then(res => {})
    }
    let params = {
      writeId: writeId.value,
      applyReason: form3.applyReason,
      type: form3.type,
      fileUrls: JSON.stringify([form3.fileUrls]),
      correctionNoticeTime: form3.correctionNoticeTime
    }
    if (form3.type === 4) {
      delete params['applyReason']
      delete params['correctionNoticeTime']
      params.fileUrls = form3.fileUrls1
      params.applyReason = form3.applyReason1
    } else {
      if (form3.reasonIds.length) {
        params.reasonIds = form3.reasonIds
        if (form3.reasonIds.indexOf(5) !== -1 && form3.remark) {
          params.remark = form3.remark
        }
      }
    }
    afterApply(params).then(res => {
      ElMessage.success(res.message)
      store.commit('updateAuth', false)
      Bus.$emit('uploadTemplateSuccess', 1)
    }).catch(err => {
      buttonLoading.value = false
    })
  }, 1000)

  const amount1 = computed({ //充值金额
    get () {
      return store.state.amount1
    },
    set (val) {
      store.commit('updateAmount1', val)
    }
  })

  const orderAmount = computed({ //抵扣金额
    get () {
      return store.state.orderAmount
    },
    set (val) {
      store.commit('updateOrderAmount', val)
    }
  })

  const discountAmount = computed({ //抵扣金额
    get () {
      return store.state.discountAmount
    },
    set (val) {
      store.commit('updateDiscountAmount', val)
    }
  })

  const deductionAmount = computed({ //抵扣金额
    get () {
      return store.state.deductionAmount
    },
    set (val) {
      store.commit('updateDeductionAmount', val)
    }
  })

  const toNoticeDetial = () => {
    cancel()
    setTimeout(() => {
      router.push({ path: '/notice-detail'})
    }, 100);
  }

  const reasonList = ref([])

  const getCorrectionList = () => {
    correctionList().then(res => {
      console.log(res);
      reasonList.value = res.data
    })
  }

  const formRef4 = ref(null)

  const form4 = reactive({
    reasonIds: [],
    remark: ''
  })


  const reasonIdsChange = (val) => {
    let index = form3.reasonIds.findIndex(item => item === 5)
    if (index > 0) {
      form3.reasonIds = [5]
    }
    if (index === 0) {
      form3.reasonIds.splice(index, 1)
    } 
  }

  const reasonIdsChange4 = (val) => {
    let index = form4.reasonIds.findIndex(item => item === 5)
    if (index > 0) {
      form4.reasonIds = [5]
    }
    if (index === 0) {
      form4.reasonIds.splice(index, 1)
    } 
  }

  const correctionId = computed({
    get () {
      return store.state.correctionId
    },
    set (val) {
      store.commit('updateCorrectionId', val)
    }
  })
  const commitReason = debounce(() => {
    let params = {
      writeId: writeId.value,
      id: correctionId.value,
    }
    if (form4.reasonIds.length) {
      params.reasonIds = form4.reasonIds
      if (form4.reasonIds.indexOf(5) !== -1 && form4.remark) {
        params.remark = form4.remark
      }
    }
    writeReason(params).then(res => {
      ElMessage.success(res.message)
      store.commit('updateAuth', false)
      Bus.$emit('writeReasonSuccess', 1)
    }).catch(err => {
       buttonLoading.value = true
    })
  }, 1000)

  const checked = ref(false)
  
  const dialogImageUrl = ref('')
  const dialogVisible = ref(false)

  const handleRemove = (uploadFile, uploadFiles) => {
    let arr = []
    fileList.value.forEach(file => {
      arr.push(file.response.data)
    });
    form3.fileUrls1 = JSON.stringify(arr)
    console.log(form3.fileUrls1);
  }

  const handlePictureCardPreview = (uploadFile) => {
    dialogImageUrl.value = uploadFile.url
    dialogVisible.value = true
  }

  const afterSaleType = ref(0) //0软著问题售后 1软件问题售后

  const afterSaleTypeChange = (val) => {
    if (val === 1) {
      form3.type = 4
    } else {
      form3.type = 3
    }
  }

  const formTypeChange = (val) => {
    if (val === 4) {
      afterSaleType.value = 1
    } else {
      afterSaleType.value = 0
    }
  }

  //套餐权益拆分
  // const equitiesList = ref([])
  const equities = ref('')

  const equitiesChange = (val) => {
    getPackage()
    // console.log('equitiesChange', val);
  }

  // const getSystemConfig = () => {
  //   systemConfig("PACKAGE_EQUITIES").then(res => {
  //     if (res && res.data) {
  //       equitiesList.value = JSON.parse(res.data)
  //       if (equitiesList.value.length) {
  //         equities.value = equitiesList.value[0].equities
  //         getPackage()
  //       }
  //       // store.commit("updateSingleCharge", res.data);
  //     }
  //   });
  // };


  const packageEquities = computed({ //权益套餐
    get () {
      return store.state.packageEquities
    },
    set (val) {
      store.commit('updatePackageEquities', val)
    }
  })

  const equitiesName = computed({ //权益套餐
    get () {
      return store.state.equitiesName
    },
    set (val) {
      store.commit('updateEquitiesName', val)
    }
  })

  const equitiesType = computed({
    get () {
      return store.state.equitiesType
    },
    set (val) {
      store.commit('updateEquitiesType', val)
    }
  })
  

  const equitiesName1 = computed({ //权益套餐
    get () {
      return store.state.equitiesName1
    },
    set (val) {
      store.commit('updateEquitiesName1', val)
    }
  })

  const TransferEquitiesName = (params) => {
    switch (params) {
      case 'CREATE_WRITE':
        return '材料生成'
      case 'ONLINE_OPERATION':
        return '在线运行'
      case 'DOWNLOAD_INSTALLATION_PACKAGE':
        return '下载安装包'
      case 'EXPEDITED_CHANNEL_BASIC_AGENT':
        return '加急通道'
      default:
        return '';
    }
  }

  const packageEquitiesIndex = ref(0)

  const companyPackageId = computed({
    get () {
      return store.state.companyPackageId
    },
    set (val) {
      store.commit('updateCompanyPackageId', val)
    }
  })
  const checkPackage = (item) => {
    let arr = JSON.parse(item.equities)
    return arr
  }

  const disabledDateFun = time => {
    return time.getTime() > Date.now();
  }

  watch(show, (newValue, oldValue) => {  
    if (show.value) {
      console.log('xxx', type.value);
      buttonLoading.value = false
      if (type.value === 0) {
        title.value = '提示'
      } else if (type.value === 2) {   
        if (formRef.value) {
          formRef.value.clearValidate()
        }
        equities.value = 'CREATE_WRITE'
        if (equitiesName1.value) {
          equities.value = equitiesName1.value
        }
        title.value = "购买套餐"
        chargeType.value = changeType1.value
        chargeType.value = 1
        form.deductionAmount = null
        getPackage()
      }else if (type.value === 3) { 
        title.value = "申请退款"
      } else if (type.value === 5) {
        companyType.value = !userInfo.value.companyId ? 1 : 0
        if (formRef1.value) {
          formRef1.value.clearValidate()
        }
        title.value = "确定开票信息"
        invoiceType.value = 0
        receiveType.value = 1
        // form1
      } else if (type.value === 7) {
        checked.value = false
        title.value = "通知公告"
        preview.data = JSON.parse(localStorage.getItem('noticeDetail'))
      } else if (type.value === 8) {
        getTemp()
        if (formRef2.value) {
          formRef2.value.clearValidate()
        }
        form2.fileName = ''
        token.value = getCookie()
        title.value = "批量生成软件"
        showErr.value = false
      } else if (type.value === 9) {
        getSS()
        getCorrectionList()
        token.value = getCookie()
        fileList.value = []
        form3.applyReason = ''
        form3.applyReason1 = ''
        if (stage.value * 1 === 11) {
          afterSaleType.value = 0          
          form3.type = 2
        } else if (stage.value * 1 === 2) {
          afterSaleType.value = 1          
          form3.type = 4
        } else {
          afterSaleType.value = 0
          if (stage.value * 1 === 62) {
            form3.type = 2
          } else {
            form3.type = 3
          }
        }
        form3.fileUrls = ''
        form3.fileUrls1 = ''
        form3.reasonIds = []
        form3.correctionNoticeTime = ''
        form3.remark = ''
        title.value = stage.value * 1 === 62 ? "更名重申" : stage.value * 1 === 63 ? "补正" : "申请售后"
        console.log(form3.reasonIds)
      } else if (type.value === 10) {
        getCorrectionList()
        if (formRef4.value) {
          formRef4.value.clearValidate()
        }
        form4.reasonIds = []
        form4.remark = ''
        title.value = "补正原因"
      } else if (type.value === 11) {
        // packageEquities
        packageEquitiesIndex.value = 0
        title.value = "选择权益套餐"
      } else {
        title.value = "提示"
      }
    }
  })
</script>

<template>
  <div class="soft-dialog">
    <el-dialog :style="{width: type === 9 ? '600px': type === 5 ? '680px' :
      type === 2 ? '608px':
      type === 11 ? '600px' : '490px'}" v-model="show" :title="title"
      :show-close="false"
      :beforeClose="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
        <div v-if="type === 0" class="auth">
          <el-icon size="56" color="#6196FF"><WarningFilled /></el-icon>
          <div class="auth-item">当前账号还未认证</div>
          <div class="auth-item">认证后才可以使用本系统功能</div>
        </div>
        <div v-else-if="type === 1" class="auth">
          <el-icon size="56" color="#6196FF"><Clock /></el-icon>
          <div class="auth-item">认证审核中，请耐心等待</div>
        </div>
        <div v-else-if="type === 2" class="auth">
          <el-form :model="form" :rules="formRules"
            ref="formRef">
            <!-- <el-form-item label="充值类型">
              <el-radio-group v-model="chargeType">
                <el-radio :label="0">直接充值</el-radio>
                <el-radio :label="1">购买套餐</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="套餐类型">
              <el-radio-group v-model="equities" @change="equitiesChange">
                <el-radio label="CREATE_WRITE">材料生成</el-radio>
                <el-radio label="ONLINE_OPERATION">在线运行</el-radio>
                <el-radio label="DOWNLOAD_INSTALLATION_PACKAGE">下载安装包</el-radio>
                <el-radio label="EXPEDITED_CHANNEL_BASIC_AGENT">加急通道</el-radio>
                
              </el-radio-group>
            </el-form-item>
            <div>
              <el-form-item label="选择套餐">
                <div class="package-list">
                  <div class="package-item"
                    @click="changePackage(item)"
                    :class="{'package-item-active': form.packageId === item.id}"
                    v-for="item in list.data"
                    :key="item.id">
                    <div class="flex-between">
                      <div class="name">{{item.name}}</div>
                      <div class="info">{{item.price}}元{{item.balance}}件（{{(item.price * 1/(item.balance * 1)).toFixed(2)}}元/件），有限期{{item.validityDays ? item.validityDays + '天' : '无限制'}}</div>
                    </div>
                    <div v-if="checkPackage(item).length > 1">
                      权益包含：
                      <span v-for="(a, ai) in checkPackage(item)" :key="ai">
                        {{a.name}}
                        <span v-if="ai !== checkPackage(item).length - 1">、</span>  
                      </span>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <div class="need-amount">
                <div>需支付</div>
                <div class="limit-price">{{Number(limitPrice).toFixed(2)}}</div>
                <div class="discount" v-if="userInfo.isAgent
                && totalPrice !== 0
                && userInfo.agentLevel
                && userInfo.agentLevel.discount !== 1">（已享受代理商专属{{userInfo.agentLevel.discount * 10}}折优惠）</div>
              </div>
            </div>
            <el-form-item label="充值方式">
              <div>
                <div class="charge-type">
                  <div class="type-item" @click="payType = 0" :class="{'type-active': payType === 0}">支付宝</div>
                </div>
                <div v-if="isMuti" style="text-align:left;color:red;line-height: 20px;margin-top:12px">说明</div>
                <div v-if="isMuti" style="text-align:left;color:red;line-height: 20px;margin-top:8px">1. 多权益套餐中的权益将自动生效，如套餐中包含材料生成、在线运行，即在生成软著后，会自动为生成的软著开通在线运行权限，其他权限同理</div>
                <div v-if="isMuti" style="text-align:left;color:red;line-height: 20px;margin-top:8px">2. 多权益套餐中的权益将自动绑定，如套餐中在线运行、下载安装包等权益只对同一套餐生成的软著有效，不可作用到其他套餐</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="type === 3" class="auth tui">
          <el-form :model="form" :rules="formRules"
            ref="formRef">
            <el-form-item label=" " prop="ques" label-width="0">
              <el-input
                type="textarea" resize="none" :rows="6" v-model="form.ques" placeholder="请输入退款原因"
              />
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="type === 4" class="auth">
          <el-icon size="56" color="#6196FF"><Clock /></el-icon>
          <div class="auth-item">退款申请审核中，请耐心等待</div>
          <div class="auth-desc">款项将退回至原支付渠道</div>
        </div>
        <div v-else-if="type === 5" class="auth kaipiao">
          <!-- <div style="margin:0 40px">
            <el-form-item label="客户类型" label-width="110px">
              <el-radio-group  v-model="companyType">
                <el-radio :label="0">企业</el-radio>
                <el-radio :label="1">非企业</el-radio>
              </el-radio-group>
            </el-form-item>
          </div> -->
          <el-form :model="form1" :rules="formRules"
            ref="formRef1" label-width="110px" >
            <div style="margin-bottom: 24px;padding:0 0 0 10px">
              <div style="font-size:14px;font-weight:700;text-align:left;padding-bottom:10px">订单详情</div>
              <div style="background: #f7f7f7;padding:10px 0;border-radius:5px">
                <div style="text-align:left">
                  <span style="display:inline-block;width:98px;text-align: right;padding-right: 12px;">订单金额</span>
                  <span>{{orderAmount.toFixed(2)}}</span>
                </div>
                <div style="text-align:left;margin:12px 0 0">
                  <span style="display:inline-block;width:98px;text-align: right;padding-right: 12px;">代理商折扣</span>
                  <span>{{discountAmount.toFixed(2)}}</span>
                </div>
                <!-- <div style="text-align:left;margin:12px 0 0">
                  <span style="display:inline-block;width:98px;text-align: right;padding-right: 12px;">余额抵扣</span>
                  <span>{{deductionAmount.toFixed(2)}}</span>
                  <span v-if="deductionAmount > 0" style="margin:10px 0 0 10px;color:red">抵扣金额请在对应的充值订单申请开票</span>
                </div> -->
                <div style="text-align:left;margin:12px 0 0">
                  <span style="display:inline-block;width:98px;text-align:right;padding-right:12px;">可开票金额</span>
                  <span style="color:#6196FF">{{amount1.toFixed(2)}}</span>
                </div>
              </div>
              
            </div>
            <template v-if="companyType === 0">
              <el-form-item label="企业名称" prop="companyName">
                <el-input v-model="form1.companyName" :disabled="userInfo.companyId !== null" placeholder="请填写企业名称" />
              </el-form-item>
              <el-form-item label="企业税号" prop="creditCode">
                <el-input v-model="form1.creditCode" placeholder="请填写企业税号" />
              </el-form-item>
              <el-form-item label="发票类型">
                <el-radio-group v-model="invoiceType" @change="invoiceTypeChange">
                  <el-radio :label="0">增值税普通发票</el-radio>
                  <el-radio :label="1">增值税专用发票</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="invoiceType === 1">
                <el-form-item label="开户行" prop="bankName">
                  <el-input v-model="form1.bankName" placeholder="请填写开户行" />
                </el-form-item>
                <el-form-item label="银行账户" prop="bankNumber">
                  <el-input v-model="form1.bankNumber" placeholder="请填写银行账户" />
                </el-form-item>
                <el-form-item label="企业地址" prop="registerAddress">
                  <el-input v-model="form1.registerAddress" placeholder="请填写企业地址" />
                </el-form-item>
                <el-form-item label="电话号码" prop="registerPhone">
                  <el-input v-model="form1.registerPhone" placeholder="请填写电话号码" />
                </el-form-item>
              </div>
              <!-- <el-form-item label="接收方式">
                <el-radio-group v-model="receiveType">
                  <el-radio :label="0">纸质发票</el-radio>
                  <el-radio :label="1" :disabled="invoiceType === 1">电子发票</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item v-if="receiveType === 0" label="发票收件地址" prop="address">
                <el-input type="textarea" resize="none" :rows="6" v-model="form1.address" placeholder="请填写发票接收地址" />
              </el-form-item>
              <el-form-item v-else label="邮箱地址" prop="mail">
                <el-input oninput="value=value.replace(/[^\w@.]/g, '')" v-model="form1.mail" placeholder="请填写电子邮箱地址" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="收件人" prop="receiveName">
                <el-input  v-model="form1.receiveName" placeholder="请填写收件人" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="联系电话" prop="receivePhone">
                <el-input v-model="form1.receivePhone" placeholder="请填写联系电话" />
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="发票抬头" prop="personName">
                <el-input v-model="form1.personName" placeholder="请填写发票抬头" />
              </el-form-item>
              <!-- <el-form-item label="接收方式">
                <el-radio-group v-model="receiveType">
                  <el-radio :label="0">纸质发票</el-radio>
                  <el-radio :label="1" :disabled="invoiceType === 1">电子发票</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item v-if="receiveType === 0" label="发票收件地址" prop="personAddress">
                <el-input type="textarea" resize="none" :rows="6" v-model="form1.personAddress" placeholder="请填写发票接收地址" />
              </el-form-item>
              <el-form-item v-else label="邮箱地址" prop="personMail">
                <el-input oninput="value=value.replace(/[^\w@.]/g, '')" v-model="form1.personMail" placeholder="请填写电子邮箱地址" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="收件人" prop="personReceiveName">
                <el-input  v-model="form1.personReceiveName" placeholder="请填写收件人" />
              </el-form-item>
              <el-form-item v-if="receiveType === 0" label="联系电话" prop="personReceivePhone">
                <el-input v-model="form1.personReceivePhone" placeholder="请填写联系电话" />
              </el-form-item>
            </template>
          </el-form>
        </div>
        <div v-else-if="type === 6" class="auth">
          <el-icon size="56" color="#6196FF"><Clock /></el-icon>
          <div class="auth-item">发票开具中，请耐心等待</div>
          <div class="auth-desc">发票将发送至您指定地址或邮箱</div>
        </div>
        <div v-else-if="type === 7" class="dialog-notice">
          <div class="dialog-notice-box" v-if="preview.data && preview.data.title">
            <div class="title">{{preview.data.title}}</div>
            <div class="content">{{preview.data.description}}</div>
            <!-- <div class="content" v-html="preview.data.content"></div> -->
            <div class="time"><span>发布日期</span>{{ formatDate(preview.data.createTime, 'YYYY-MM-DD') }}</div>
          </div>
        </div>
        <div v-else-if="type === 8" class="dialog-muti-add">
          <el-form :model="form2" :rules="formRules"
            ref="formRef2">
            <el-form-item prop="fileName">
                <div class="up-auth">
                  <div class="upload" v-show="form2.fileName === ''">
                    <el-upload
                      ref="uploadRef"
                      drag
                      action="#"
                      :headers="{
                        'Token': token,
                      }"
                      accept=".xls,.xlsx"
                      :show-file-list="false"
                      class="upload-demo"
                      :auto-upload="false"
                      :before-upload="handleBeforeUpload"
                      :on-change="handleChange"
                      :on-success="handleSuccess"
                    >
                      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                      <div class="el-upload__text">
                        点击上传.xls文件
                      </div>
                    </el-upload>
                  </div>
                  <div class="upload-item" v-show="form2.fileName !==''">
                    <div class="name" @click="downloadUpFile">{{ form2.fileName }}</div>
                    <el-icon class="el-icon--del" @click="removeFile"><Delete /></el-icon>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div><a href="javascript:;" @click="downloadTemp" class="download">下载模板文件</a></div>
            <div style="text-align: center;padding: 10px 24px 0;color:red">批量生成的材料暂不支持合作开发及代理提交</div>
            <div v-if="showErr" style="text-align: left;padding: 10px 24px;color:red">{{showErrMsg}}</div>
        </div>
        <div v-else-if="type === 9" class="dialog-after-sale">
          <el-form :model="form3" :rules="formRules"
            ref="formRef3" label-width="120px" label-position="top">
            <div v-if="stage < 60 && stage !== 11" class="type-title">
              <span>售后类型</span>
              <!-- <span class="tips">（请谨慎选择）</span> -->
            </div>
            <el-form-item v-if="stage < 60 && stage !== 11" label="" label-width="0px">
              <el-radio-group v-model="afterSaleType"  @change="afterSaleTypeChange">
                <el-radio :disabled="stage === 11" :label="1">软件问题售后</el-radio>
                <el-tooltip
                    effect="dark"
                    placement="bottom"
                    :disabled="stage !== 2">
                    <template #content>
                      <div style="width:200px">软件还未提至版权中心，暂不可申请软著类售后，若已线下提交，请点击【同步状态】，将材料同步至【待补正】</div>
                    </template>
                    <el-radio :disabled="stage === 2" :label="0">软著问题售后</el-radio>
                </el-tooltip>
              </el-radio-group>
            </el-form-item>
            <div class="type-title" v-if="afterSaleType === 0 && stage < 60">
              <span>处理方式</span>
              <!-- <span class="tips">（请谨慎选择）</span> -->
            </div>
            <el-form-item label="" label-width="0px" v-if="afterSaleType === 0 && stage < 60">
              <el-radio-group  v-model="form3.type" @change="formTypeChange">
                <el-radio v-if="afterSaleType === 0 && stage !== 11" :label="3">补正</el-radio>
                <el-radio v-if="afterSaleType === 0" :label="2">更名重申</el-radio>
                <el-radio v-if="afterSaleType === 1" :label="4">重新生成材料</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="tips1" v-if="form3.type === 3 && stage < 60">针对当前材料，按照补正要求重新生成程序鉴别材料。</div>
            <div class="tips1" v-else-if="form3.type === 2 && stage < 60">放弃当前材料申请，更换名称生成新材料重新提交。</div>
            <div class="tips1" v-else-if="form3.type === 4 && stage < 60">针对当前软件缺陷（如数据缺失、界面不完整、功能偏差大等），重新生成软件及配套材料</div>
            <div style="padding:0" v-if="form3.type !== 4">
              <el-form-item label="上传补正通知截图" prop="fileUrls">
                <div class="up-after">
                  <div v-if="form3.fileUrls" class="photo">
                    <el-image :src="form3.fileUrls" :preview-src-list="[form3.fileUrls]"></el-image>
                    <el-icon class="el-icon-del" @click="form3.fileUrls = ''"><Delete /></el-icon>
                  </div>
                  <div class="upload" v-else>
                    <el-upload
                      :action="uploadUrl1"
                      :headers="{
                        'Token': token,
                      }"
                      accept="image/*"
                      :show-file-list="false"
                      class="upload-demo"
                      :before-upload="beforeUpload"
                      :on-success="handleSuccess1"
                      :on-error="handleError"
                    >
                      <el-button :loading="isUpload" type="primary">选择文件</el-button>
                    </el-upload>
                  </div>
                  <div class="example"><a href="javascript:;" @click="downloadSS" class="download">截图示例</a></div>
                </div>
              </el-form-item>
              <el-form-item label="下补正通知时间" prop="correctionNoticeTime">
                <div>
                  <el-date-picker
                    style="height: 30px;width:200px"
                    v-model="form3.correctionNoticeTime"
                    type="date"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    :disabledDate="disabledDateFun"
                  />
                </div>
              </el-form-item>
              <el-form-item label="补正原因">
                <!-- {{form3.reasonIds}} -->
                <el-checkbox-group v-model="form3.reasonIds" @change="reasonIdsChange">
                  <el-checkbox v-for="(item, index) in reasonList" :key="index" :label="item.id">{{item.name}}</el-checkbox>
                </el-checkbox-group>
                <el-input style="margin-top: 10px" v-if="form3.reasonIds.indexOf(5) !== -1" type="textarea" resize="none" :rows="4" v-model="form3.remark" placeholder="请填写补正原因" />
              </el-form-item>
              <el-form-item label="其他补充说明">
                <el-input type="textarea" resize="none" :rows="4" v-model="form3.applyReason" placeholder="请填写补充说明" />
              </el-form-item>
            </div>
            <div style="padding:0" v-else>
              <el-form-item label="上传软件缺陷截图" prop="fileUrls1">
                <div class="upload-area">
                  <el-upload
                    v-model:file-list="fileList"
                    :action="uploadUrl1"
                    :headers="{
                      'Token': token,
                    }"
                    accept="image/*"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :limit="3"
                    :before-upload="beforeUpload2"
                    :on-success="handleSuccess2"
                  >
                    <el-icon><Plus /></el-icon>
                  </el-upload>
                  <span class="up-tips">{{fileList.length}}/3</span>
                </div>
                <!-- <div class="up-after">
                  <div v-if="form3.fileUrls" class="photo">
                    <el-image :src="form3.fileUrls" :preview-src-list="[form3.fileUrls]"></el-image>
                    <el-icon class="el-icon-del" @click="form3.fileUrls = ''"><Delete /></el-icon>
                  </div>
                  <div class="upload" v-else>
                    <el-upload
                      :action="uploadUrl1"
                      :headers="{
                        'Token': token,
                      }"
                      accept="image/*"
                      :show-file-list="false"
                      class="upload-demo"
                      :before-upload="beforeUpload"
                      :on-success="handleSuccess1"
                      :on-error="handleError"
                    >
                      <el-button :loading="isUpload" type="primary">选择文件</el-button>
                    </el-upload>
                  </div>
                  <div class="example"><a href="javascript:;" @click="downloadSS" class="download">截图示例</a></div>
                </div> -->
              </el-form-item>
              <el-form-item label="说明" prop="applyReason1">
                <el-input type="textarea" resize="none" :rows="4" v-model="form3.applyReason1" placeholder="请描述软件存在的缺陷，如数据缺失、界面不完整、功能偏差大等" />
              </el-form-item>
            </div>
          </el-form>
          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" />
          </el-dialog>
        </div>
        <div v-else-if="type === 10" class="dialog-after-sale">
          <el-form :model="form4" :rules="formRules"
            ref="formRef4" label-width="120px">
            <el-form-item label="补正原因" prop="reasonIds">
              <el-checkbox-group v-model="form4.reasonIds" @change="reasonIdsChange4">
                <el-checkbox v-for="(item, index) in reasonList" :key="index" :label="item.id">{{item.name}}</el-checkbox>
              </el-checkbox-group>
              <el-input style="margin-top: 10px" v-if="form4.reasonIds.indexOf(5) !== -1" type="textarea" resize="none" :rows="4" v-model="form4.remark" placeholder="请填写补正原因" />
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="type === 11" class="dialog-equities">
          <div class="equities-title"><span v-if="equitiesType !== 'multiple'">您有多个套餐拥有此项权益，</span>请选择要使用的套餐</div>
          <div v-for="(item, index) in packageEquities" :key="index" class="equities-item"
            :class="{'equities-active': packageEquitiesIndex === index}"
            @click="packageEquitiesIndex = index">
            <div class="name">{{item.packageName}}</div>
            <div class="quanyi">
              <span style="margin-right:24px">{{TransferEquitiesName(equitiesName)}}</span>
              <span>剩余<span style="margin-left:5px;color:red">{{item.balance}}</span></span>
            </div>
            <div class="due">{{ formatDate(item.dueDate, 'YYYY-MM-DD') }}<span style="margin-left:5px">到期</span></div>
          </div>
        </div>
      <template #footer>
        <span class="dialog-footer">
          <div>
            <div v-if="type === 7" class="check-item"><el-checkbox v-model="checked" label="不再提醒" size="large" /></div>
          </div>
          <div class="flex-end">
            <div v-if="type !== 7 && type !== 1 && type !== 4 && type !== 6" class="btn-item" @click="cancel">{{ type >= 2 ? '取消': '稍后认证'}}</div>
            <div v-if="type === 7" class="view-detial" @click="toNoticeDetial">查看详情</div>
            <div :class="{'add_disabled': type === 5 && amount1 === 0 || (type === 8 && showErr) || buttonLoading}" class="btn-item btn-active btn-load" @click="submit"> <el-icon  v-if="buttonLoading" class="is-loading" style="margin-right:4px"><Loading /></el-icon>{{ type >= 1 ? '确定': '认证'}}</div>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="less">
.soft-dialog {
  .el-dialog {
    border-radius: 8px
  }
  .el-dialog__header {
    text-align: left;
    margin-right: 0;
    border-bottom: 1px solid #D8D8D8;
  }
  .el-checkbox {
    height: 31px;
  }
  .el-form {
    margin: 0 40px;
    .el-form-item {
      margin-bottom: 24px;
      .max-amount {
        margin-left: 8px;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #6196FF;
      }
    }
    .need-amount {
      margin: 24px 0 24px 70px;
      text-align: left;
      font-size: 18px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #1A1A1A;
      height: 23px;
      line-height: 23px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div {
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .limit-price {
        margin-left: 16px;
        font-size: 18px;
        font-weight: 700;
        color: #6196FF;
      }
      .discount {
        // margin-left: 32px;
        font-size: 14px;
        font-weight: 700;
        color: #EA0000;
      }
    }
    .package-list {
      display: flex;
      flex-direction: column;
      .package-item {
        // width: 300px;
        // height: 43px;
        background: #FFFFFF;
        border-radius: 4px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 12px 16px;
        border: 1px solid #767676;
        cursor: pointer;
        .name {
          font-size: 14px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 700;
          color: #767676;
          margin-right: 8px;
        }
        .info {
          font-size: 12px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #767676;
        }
      }
      .package-item + .package-item {
        margin-top: 8px;
      }

      .package-item-active {
        border: 1px solid #6196FF !important;
        color: #6196FF !important;
        .name {
          color: #6196FF !important;
        }
        .info {
          color: #6196FF !important;
        }
      }
    }
  }
  .el-input-number {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
    .el-icon {
      margin-bottom: 0 !important;
    }
  }
  .tui {
    .el-form {
      margin: 0 40px;
      .el-form-item {
        margin-bottom: 0px;
        .el-form-item__label:before {
          content: '';
        }
      }
    }
  }
  .kaipiao {
    padding-bottom: 0px !important;
  }
  .auth {
    padding: 22px 0 29px;
    .el-form {
      margin: 0 30px;
    }
    .el-icon {
      margin-bottom: 12px;
    }
    .auth-item {
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #1A1A1A;
      line-height: 23px;
    }
    .auth-desc {
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #AAAAAA;
      margin-top: 8px;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #D8D8D8;
    padding: 24px;
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add_disabled {
      background: #bbb !important;
      cursor: no-drop !important;
    }
    .add_disabled:hover {
      border-color: #bbb !important;
    }
    .btn-load {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .view-detial {
      color: #6196FF;
      margin-right: 16px;
      font-size: 14px;
      cursor: pointer;
    }
    .add_submit {
      width: 100px;
      height: 31px;
      line-height: 31px;
      background: #6196FF;
      border-radius: 50px;
      font-size: 14px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
    .add_cancel {
      width: 100px;
      height: 29px;
      line-height: 29px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 1px solid #6196FF;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #6196FF;
      margin-left: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
  .charge-type {
    display: flex;
  }
  .type-item {
    width: 118px;
    height: 53px;
    background: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .type-item + .type-item {
    margin-left: 13px;
  }
  .type-active {
    border: 1px solid #6196FF;
    color: #6196FF;
  }
  .dialog-notice {
    background: #FAFAFA;
    display: flex;
    .dialog-notice-box {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: #FFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      .title {
        color: #1A1A1A;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 12px;
      }
      .content {
        // flex: 1;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 20;
        // // overflow: hidden;
        // text-overflow: ellipsis;
        // word-break: break-all;
        color: #1A1A1A;
        max-height: 300px;
        overflow-y: auto;
      }
      .time {
        text-align: right;
        color: #9E9E9E;
        height: 23px;
        margin-top: 16px;
        span {
          color: #1A1A1A;
          margin-right: 24px;
        }
      }
    }
  }
  .dialog-muti-add {
    .el-form {
      .el-form-item {
        .el-form-item__content{
          .el-form-item__error{
            top: 90%;
            left: 110px;
          }
        }
      }
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .up-auth {
      width: 100%;
      a {
        color: #6196FF;
      }
      .upload {
        margin: 9px auto 16px;
        width: 142px;
        height: 121px;
        border-radius: 8px;
        // border: 1px solid #D8D8D8;
        .upload-demo {
          width: 142px;
          height: 121px;
          .el-upload {
            height: 100%;
            width: 100%;
            .el-upload-dragger {
              padding: 0;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .el-icon {
                color: #D8D8D8;
                width: 42px;
                height: 38px;
                margin-bottom: 8px;
              }
            }
            .el-upload__text {
              font-size: 14px;
              color: #AAA;
            }
          }
        }
      }
      .upload-item {
        width: 350px;
        margin: 20px auto 16px;
        height: 55px;
        padding-left: 18px;
        padding-right: 26px;
        color: #666;
        display: flex;
        align-items: center;
        justify-content: space-between;
        justify-content: center;
        .name {
          cursor: pointer;
          height: 20px;
          line-height: 20px;
          margin-right: 8px;
        }
        i {
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
    .download {
      margin-top: 6px;
    }
  }
  .dialog-after-sale {
    .el-form {
      margin: 0 16px;
      .el-form-item {
        margin-bottom: 18px;
        .el-form-item__label {
          text-align: left;
          justify-content: flex-start;
        }
        .el-form-item__label::before {
          margin-right: 0;
        }
        .upload-area {
          height: 150px;
          overflow: hidden;
          position: relative;
          display: flex;
          .up-tips {
            height: 100%;
            display: flex;
            align-items: flex-end;
            margin-left: 5px;
            // position: absolute;
            // right: -16px;
          }
        }
        .el-checkbox-group {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
    .type-title {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      .tips {
        // position: absolute;
        color: red;
      }
    }
    .tips1 {
      text-align: left;
      margin-top: -10px;
      margin-bottom: 24px;
      color: red;
    }
    .up-after {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      .photo {
        position: relative;
        width: 150px;
        height: 90px;
        text-align: center;
        background: #fdfdfd;
        border: 1px solid #eaeaea;
        border-radius: 3px;
        margin-right: 10px;
        .el-image {
          width: 100%;
          height: 100%;
          display: block;
        }
        .el-icon-del {
          position: absolute;
          right: -15px;
          top: 0;
          cursor: pointer;
        }
      }
    }
  }

  .dialog-equities {
    .equities-title {
      text-align: left;
      margin-bottom: 16px;
    }
    .equities-active {
      color: #6196FF;
      border: 1px solid #6196FF !important;
    }
    .equities-item + .equities-item {
      margin-top: 12px;
    }
    .equities-item {
      cursor: pointer;
      padding: 16px 24px;
      border: 1px solid #000;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        font-weight: 700;
        font-size: 18px;
        flex: 1;
        text-align: left;
      }
      .quanyi {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .due {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
</style>