import request from '../utils/request'

// login
export function ssoLogin(params) {
  return request({
    url: '/sso/login',
    method: 'POST',
    params: params
  })
}


// 获取用户信息
export function ssoInfo(params) {
  return request({
    url: '/sso/info',
    method: 'GET',
    params: params
  })
}

export function systemConfig(code) {
  return request({
    url: '/systemConfig/' + code,
    method: 'GET'
  })
}

export function ssoUpdatePassword(params) {
  return request({
    url: '/sso/updatePassword',
    method: 'POST',
    params: params
  })
}

export function ssoUpdatePhone(params) {
  return request({
    url: '/sso/updatePhone',
    method: 'POST',
    params: params
  })
}

export function ssoPhoneUpdatePassword(params) {
  return request({
    url: '/sso/phone/updatePassword',
    method: 'POST',
    params: params
  })
}

export function ssoNamePhone(params) {
  return request({
    url: '/sso/namePhone',
    method: 'GET',
    params: params
  })
}

// 资费说明
export function consumption(params) {
  return request({
    url: '/banner/consumption',
    method: 'GET',
    params: params
  })
}

// 列表
export function writePage(params) {
  return request({
    url: '/write/page',
    method: 'GET',
    params: params
  })
}

// 创建软著
export function writeCreate (data) {
  return request({
    url: '/write/create',
    method: 'POST',
    data
  })
}

// 根据软件名称生成目的-描述-主要功能
export function writeDescription (data) {
  return request({
    url: '/write/description',
    method: 'POST',
    data
  })
}

// 根据软件名称返回大纲
// export function writeOutline (data) {
//   return request({
//     url: '/write/outline',
//     method: 'POST',
//     data
//   })
// }

export function v2WriteOutline (data) {
  return request({
    url: '/v2/write/outline',
    method: 'POST',
    data
  })
}

// 保存更新申请表
// export function writeUpdate (data) {
//   return request({
//     url: '/write/update',
//     method: 'POST',
//     data
//   })
// }

// export function writeUpdate (data) {
//   return request({
//     url: '/v2/write/update',
//     method: 'POST',
//     data
//   })
// }

export function writeUpdate (data) {
  return request({
    url: '/v2/write/v2/update',
    method: 'POST',
    data
  })
}

export function writeApplicant (data) {
  return request({
    url: '/v2/write/applicant',
    method: 'POST',
    data
  })
}

// 保存更新大纲
// export function writeOutlineUpdate (data) {
//   return request({
//     url: '/write/outline/update',
//     method: 'POST',
//     data
//   })
// }

// 保存更新大纲
export function v2WriteOutlineUpdate (data) {
  return request({
    url: '/v2/write/outline/update',
    method: 'POST',
    data
  })
}

// 根据大纲返回正文(同步)
export function writeMain (data) {
  return request({
    url: '/write/main',
    method: 'POST',
    data
  })
}

// 根据大纲返回正文(异步)
export function writeContent (data) {
  return request({
    url: '/write/content',
    method: 'POST',
    data
  })
}

// 正文列表
export function writeContentList(params) {
  return request({
    url: '/write/content',
    method: 'GET',
    params: params
  })
}

export function writeContentText(contentId) {
  return request({
    url: '/write/content/' + contentId,
    method: 'GET'
  })
}

// 保存更新正文
export function writeContentUpdate (data) {
  return request({
    url: '/write/content/update',
    method: 'POST',
    data
  })
}

// 根据功能正文返回html和截图
export function writeHtml (data) {
  return request({
    url: '/write/html',
    method: 'POST',
    data
  })
}

// 导出软著-完整的包
export function writeExport(params) {
  return request({
    url: '/write/export',
    method: 'GET',
    params: params
  })
}

// 导出软著-用户手册word
export function writeExportContent(params) {
  return request({
    url: '/write/export/content',
    method: 'GET',
    params: params
  })
}

export function writeDel (data) {
  return request({
    url: '/write/del/' + data.id,
    method: 'DELETE'
  })
}

// 获取申请表基本信息
export function writeSoftware(id) {
  return request({
    url: '/write/software/' + id,
    method: 'GET'
  })
}

// 改-扩写
export function writeRewrite (data) {
  return request({
    url: '/write/rewrite',
    method: 'POST',
    data
  })
}

// 文生图
export function TextToImage (data) {
  return request({
    url: '/write/textToImage',
    method: 'POST',
    data
  })
}

export function alipayForm(data) {
  return request({
    url: '/pay/repair/pay-form-web',
    method: 'POST',
    data
  })
}

export function createPayLog (data) {
  return request({
    url: '/pay/createPayLog',
    method: 'POST',
    data
  })
}

export function writeScreenshot (data) {
  return request({
    url: '/write/screenshot',
    method: 'POST',
    data
  })
}

export function feedBack (data) {
  return request({
    url: '/feedBack',
    method: 'POST',
    data
  })
}

export function writeRepeat (params) {
  return request({
    url: '/write/repeat',
    method: 'GET',
    params
  })
}

export function htmlDownload (params) {
  return request({
    url: '/write/html/download/' + params.writeId,
    method: 'GET'
  })
}

export function writeDesignate (params) {
  return request({
    url: '/write/v2/designate',
    method: 'GET',
    params
  })
}

export function writeDesignateV3 (data) {
  return request({
    url: '/write/v3/designate',
    method: 'POST',
    data
  })
}

export function agentDomain (params) {
  return request({
    url: '/admin/agent/byDomain',
    method: 'GET',
    params
  })
}

export function writeAnew (writeId) {
  return request({
    url: '/write/anew/platform/' + writeId,
    method: 'GET'
  })
}

export function exportCode (params) {
  return request({
    url: '/write/export/code',
    method: 'GET',
    params
  })
}

export function writeApplicationUpdate (data) {
  return request({
    url: '/v2/write/application/update',
    method: 'PUT',
    data
  })
}

export function writeDocumentation (params) {
  return request({
    url: '/v2/write/documentation/' + params.writeId,
    method: 'GET',
    params
  })
}

export function getCopyrightAccountToken (params) {
  return request({
    url: '/copyright/accountToken',
    method: 'GET',
    params
  })
}

export function saveCopyrightAccountToken (data) {
  return request({
    url: '/copyright/accountToken',
    method: 'POST',
    data
  })
}

export function copyrightSubmit (data) {
  return request({
    url: '/copyright/submit',
    method: 'POST',
    data
  })
}

export function copyrightGetParam (params) {
  return request({
    url: '/copyright/getParam',
    method: 'GET',
    params
  })
}

export function copyrightgGetContentHash (params) {
  return request({
    url: '/copyright/getContentHash',
    method: 'GET',
    params
  })
}



export function writeCheck (params) {
  return request({
    url: '/write/verify',
    method: 'POST',
    params
  })
}

export function writeDetail (params) {
  return request({
    url: '/write/detail',
    method: 'GET',
    params
  })
}

export function writeAnewFile (data) {
  return request({
    url: '/write/anew/file',
    method: 'POST',
    data
  })
}

export function writeUpdateFile (data) {
  return request({
    url: '/write/update/file',
    method: 'POST',
    data
  })
}

export function copyrightAddInformation (data) {
  return request({
    url: '/copyright/addInformation',
    method: 'POST',
    data
  })
}

export function copyrightUploadFile (data) {
  return request({
    url: '/copyright/uploadFile',
    method: 'POST',
    data
  })
}

export function uploadSignaturePage (data) {
  return request({
    url: '/write/uploadSignaturePage',
    method: 'POST',
    data
  })
}

export function copyrightUploadSignedSignaturePageFile (data) {
  return request({
    url: '/copyright/uploadSignedSignaturePageFile',
    method: 'POST',
    data
  })
}

export function copyrightSave (data) {
  return request({
    url: '/copyright/save/' + data.writeId,
    method: 'POST'
  })
}


export function copyrightSubmitSealMaterial (data) {
  return request({
    url: '/copyright/submitSealMaterial',
    method: 'POST',
    data
  })
}

export function copyrightSbmit (data) {
  return request({
    url: '/copyright/submit/' + data.writeId,
    method: 'POST'
  })
}

export function writeSyncStatus (data) {
  return request({
    url: '/v2/write/syncStatus/' + data.writeId,
    method: 'GET'
  })
}

export function writeAnewPlatform(data) {
  return request({
    url: '/write/anew/platform/' + data.writeId,
    method: 'GET'
  })
}

export function writeCorrect(data) {
  return request({
    url: '/write/correct',
    method: 'POST',
    data
  })
}

export function getByHash(data) {
  return request({
    url: '/d/' + data.key,
    method: 'GET',
  })
}

export function writeDownload(params) {
  return request({
    url: '/v2/write/download/' + params.writeId,
    method: 'GET'
  })
}

export function writeReset(params) {
  return request({
    url: '/v2/write/reset/' + params.writeId,
    method: 'GET'
  })
}

export function writePlan(params) {
  return request({
    url: '/v2/write/plan',
    method: 'GET',
    params
  })
}

export function writeRemark(data) {
  return request({
    url: '/v2/write/remark',
    method: 'PUT',
    data
  })
}

export function writeComplete(data) {
  return request({
    url: '/v2/write/complete',
    method: 'PUT',
    data
  })
}

export function writeCommit(data) {
  return request({
    url: '/v2/write/update/copyrightStatus',
    method: 'PUT',
    data
  })
}

export function writeReason(data) {
  return request({
    url: '/v2/write/reason',
    method: 'PUT',
    data
  })
}

export function writeAfresh(data) {
  return request({
    url: '/v2/write/afresh/' + data.writeId,
    method: 'GET',
  })
}

export function onlineSubmitWrite(data) {
  return request({
    url: '/write/onlineSubmitWrite',
    method: 'POST',
    data
  })
}

// export function exeCreate(data) {
//   return request({
//     url: '/exe/create',
//     method: 'POST',
//     data
//   })
// }

export function exeCreate(data) {
  return request({
    url: '/exe/v2/create',
    method: 'POST',
    data
  })
}

export function writeSearch(params) {
  return request({
    url: '/v2/write/search',
    method: 'GET',
    params
  })
}

export function copyrightLoginPre(data) {
  return request({
    url: '/copyright/loginPre',
    method: 'POST',
    data
  })
}

export function copyrightLoginNext(data) {
  return request({
    url: '/copyright/loginNext',
    method: 'POST',
    data
  })
}

export function writeOnlineSubmit(params) {
  return request({
    url: '/v2/write/online/submit',
    method: 'GET',
    params
  })
}


export function submitWriteTryAgain(params) {
  return request({
    url: '/write/submitWriteTryAgain',
    method: 'GET',
    params
  })
}

export function uploadSignaturePageTryAgain(params) {
  return request({
    url: '/write/uploadSignaturePageTryAgain',
    method: 'GET',
    params
  })
}

export function systemConfigAstrict() {
  return request({
    url: '/systemConfig/astrict',
    method: 'GET',
  })
}


var ua = navigator.userAgent.toLowerCase()

export function setOnlineSubmitStatusSuccess (data) {
  return request({
    url: '/write/setOnlineSubmitStatusSuccess',
    method: 'post',
    header: {
      'Content-type': 'multipart/form-data'
    },
    transformRequest () {
      if (ua.includes('dingtalk')) {
        data.toString = () => '[object FormData]'
      }
      return data
    },
    data
  })
}

export function setUploadSignaturePageStatusSuccess (data) {
  return request({
    url: '/write/setUploadSignaturePageStatusSuccess',
    method: 'post',
    header: {
      'Content-type': 'multipart/form-data'
    },
    transformRequest () {
      if (ua.includes('dingtalk')) {
        data.toString = () => '[object FormData]'
      }
      return data
    },
    data
  })
}

export function setCopyrightStatusWaitingMaterials (params) {
  return request({
    url: '/write/setCopyrightStatusWaitingMaterials',
    method: 'post',
    params
  })
}

export function writeUserSyn(writeId) {
  return request({
    url: '/v2/write/user/syn/' + writeId,
    method: 'GET',
  })
}

export function writeExcel (data) {
  return request({
    url: '/write/excel',
    method: 'post',
    data
  })
}

export function correctionPage (params) {
  return request({
    url: '/correction/page',
    method: 'get',
    params
  })
}

export function anewPlatformAuto (params) { //重新生成代码-自动提交-新平台
  return request({
    url: '/write/anew/platform-auto/' + params.writeId,
    method: 'get'
  })
}

export function goAutoSubmitCorrection (data) { //去自动提交补正
  return request({
    url: '/after/sale/goAutoSubmitCorrection',
    method: 'post',
    data
  })
}

export function saveAutoSubmitCorrection (data) { //保存自动提交补正
  return request({
    url: '/after/sale/saveAutoSubmitCorrection',
    method: 'post',
    data
  })
}

export function goAutoSubmitCorrectionTryAgain (params) { //重试自动提交补正
  return request({
    url: '/after/sale/goAutoSubmitCorrectionTryAgain',
    method: 'GET',
    params
  })
}


export function syncCorrection (params) { //补正同步
  return request({
    url: '/v2/write/user/syncCorrection',
    method: 'GET',
    params
  })
}

export function writeEquities (params) { //权益校验
  return request({
    url: '/v2/write/equities',
    method: 'GET',
    params
  })
}

export function companyPackage (params) { //获取可用套餐
  return request({
    url: '/company/package/package',
    method: 'GET',
    params
  })
}


export function writeHash (params) { //获取htmlhash
  return request({
    url: '/v2/write/hash/' + params.writeId,
    method: 'GET',
    params
  })
}

export function writeHdGetBaseOwners (params) { //华代-获取待验证的用户信息
  return request({
    url: '/write/hd/getBaseOwners',
    method: 'GET',
    params
  })
}

export function writeHdCheckHdVerificationCode (writeId, data) { //华代-校验验证码，如果验证正确会直接提交案件
  return request({
    url: '/write/hd/checkHdVerificationCode/' + writeId,
    method: 'POST',
    data
  })
}

export function writeHdSendVerificationCode (writeId, data) { //华代-校验验证码，如果验证正确会直接提交案件
  return request({
    url: '/write/hd/sendVerificationCode/' + writeId,
    method: 'POST',
    data
  })
}

export function writeHdGetSignaturePageFileUrls (writeId) { //华代-获取原始签章页和已上传签证页url
  return request({
    url: '/write/hd/getSignaturePageFileUrls/' + writeId,
    method: 'POST',
  })
}

export function writeHdUploadSignedSignaturePageFile (data) { //华代-上传已签署的签章页
  return request({
    url: '/write/hd/uploadSignedSignaturePageFile',
    method: 'POST',
    data
  })
}

export function writeHdSubmitFillingUnsorting (writeId) { //华代-提交案件
  return request({
    url: '/write/hd/submitFillingUnsorting/' + writeId,
    method: 'POST'
  })
}


export function writeManualVerifyApply (data) { //手动验证申请
  return request({
    url: '/write/manualVerifyApply',
    method: 'POST',
    data
  })
}


export function updateCcCheckedAccountDesc (id, data) { //更新版权中心账号检测信息
  return request({
    url: '/copyright/updateCcCheckedAccountDesc/' + id,
    method: 'POST',
    data
  })
}

export function updateCcAddress (id, data) { //更新著作权人收件单位地址
  return request({
    url: '/copyright/updateCcAddress/' + id,
    method: 'POST',
    data
  })
}

export function v2WriteUserBatchSyn (params) { //更新著作权人收件单位地址
  return request({
    url: '/v2/write/user/batchSyn',
    method: 'GET',
    params
  })
}

export function writeExcelV2 (data) {
  return request({
    url: '/write/excel/v2',
    method: 'post',
    data
  })
}

export function aftersaleClose (params) {
  return request({
    url: '/after/sale/close',
    method: 'GET',
    params
  })
}
