<script setup>
import { onMounted, ref, watchEffect, reactive, computed, onUnmounted } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import {
  consumption,
  ssoUpdatePassword,
  ssoInfo,
  systemConfig,
  ssoUpdatePhone,
  agentDomain
} from "../api/index";
import { smsGetCode } from "../api/authentication";
import { bannerList } from "../api/notice";
import { formatDate } from "../assets/js/date";
import { removeCookie } from "../assets/js/cookie";
import Bus from "../js/Bus";
import { useStore } from "vuex";
import noticebar from "../components/NoticeBar.vue";
import PayLoadding from "../components/PayLoadding.vue"
import Question from "../components/Question.vue"
const store = useStore();
const router = useRouter();
const route = useRoute();
const menuVisible = ref(false);
const visible = ref(false);
const username = ref("");
const infoMsg = ref("");
const isHome = ref(false);
const menuList = ref(["工作台", "成员管理", "消费记录", "统计分析"]);
const activeIndex = ref("/home");
const upPassVisible = ref(false);
const upPhoneVisible = ref(false);
const formLabelWidth = "80px";
const upPassFormRef = ref(null);
const upPhoneFormRef = ref(null);
const showText = ref(true);
const second = ref(60);
const btnLoading = ref(false);
const upPassForm = reactive({
  oldPassword: "",
  newPassword: ""
});
const upPhoneForm = reactive({
  password: "",
  telephone: "",
  authCode: ""
});
const validatorPwd = (rule, value, callback) => {
  // 检验密码强度
  if (!value.trim().length) {
    return callback(new Error("请输入密码！"));
  }
  if (/\d/.test(value) && /[a-zA-Z]/.test(value)) return callback();
  callback(new Error("设置6位及以上字母+数字组合的密码"));
};
const validatorPhone = (rule, value, callback) => {
  // console.log(value);
  if (userInfo.value.phone && userInfo.value.phone === value.trim()) {
    return callback(new Error("新手机号不能与原手机号重复"));
  }
  callback();
};
const formRules = {
  oldPassword: [{ required: true, message: "密码不能为空", trigger: "blur" }],
  newPassword: [
    { required: true, message: "密码不能为空", trigger: "blur" },
    { min: 6, message: "设置6位及以上字母+数字组合的密码", trigger: "blur" },
    { validator: validatorPwd, triger: "blur" }
  ],
  password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
  telephone: [
    { required: true, message: "手机号不能为空", trigger: "blur" },
    { validator: validatorPhone, triger: "blur" }
  ],
  authCode: [{ required: true, message: "验证码不能为空", trigger: "blur" }]
};

const singleCharge = computed({
  get() {
    return store.state.singleCharge;
  },
  set(val) {
    store.commit("updateSingleCharge", val);
  }
});

const amount = computed({
  get() {
    return store.state.amount;
  },
  set(val) {
    store.commit("updateAmount", val);
  }
});

const userInfo = computed({
  get() {
    return store.state.userInfo;
  },
  set(val) {
    store.commit("updateUserInfo", val);
  }
});

const packageList = computed({
  get() {
    return store.state.packageList;
  },
  set(val) {
    store.commit("updatePackageList", val);
  }
});

const select = (index, indexPath) => {
  updateActiveMenuIndex(index);
};
const updateActiveMenuIndex = index => {
  activeIndex.value = index;
};
const handleOpen = (key, keyPath) => {
  // console.log('handleOpen', key, keyPath);
};
const handleClose = (key, keyPath) => {
  // console.log('handleClose', key, keyPath);
};

const handleVisible = visible => {
  menuVisible.value = visible;
};

const logout = () => {
  ElMessageBox.confirm("确定退出登录吗？", "系统提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    confirmButtonClass: 'btn-item btn-active',
    cancelButtonClass: 'btn-item',
  })
    .then(() => {
      removeCookie();
      store.commit("updateUserInfo", {});
      router.replace({ path: "/login" });
    })
    .catch(() => {});
};

const getSystemConfig = () => {
  systemConfig("SINGLE_CHARGE").then(res => {
    if (res && res.data) {
      store.commit("updateSingleCharge", res.data);
    }
  });
};

const getUserInfo1 = () => {
  ssoInfo().then(res => {
    if (res) {
      store.commit("updateUserInfo", res.data);
      store.commit("updateAmount", res.data.balance);
      store.commit("updatePackageList", res.data.packageList);
    }
  });
};

const isGetUser = ref(false)

const getUserInfo = isCreate => {
  ssoInfo().then(res => {
    if (res) {
      getPackageEquities()
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      store.commit("updateUserInfo", res.data);
      store.commit("updateAmount", res.data.balance);
      store.commit("updatePackageList", res.data.packageList);
      isGetUser.value = true
      // 企业认证状态:0->未通过；1->企业认证通过;2->代理人商认证通过；3->企业认证审核中；4->代理人商认证审核中 5个人状态通过
      if (res.data.authStatus === 0) {
        store.commit("authType", 0);
        store.commit("updateAuth", true);
      } else if (res.data.authStatus === 3) {
        store.commit("authType", 1);
        store.commit("updateAuth", true);
      } else {
        if (isCreate) {
          if (res.data.isAgent) {
            let num = 100;
            if (
              res.data.balance * 1 <
                (singleCharge.value *
                  num *
                  (res.data.agentLevel.discount * num)) /
                  (num * num) &&
              !res.data.packageList.length
            ) {
              ElMessage.error("余额不足，请充值");
              return;
            }
          } else {
            if (
              res.data.balance * 1 < singleCharge.value * 1 &&
              !res.data.packageList.length
            ) {
              ElMessage.error("余额不足，请充值");
              return;
            }
          }
          localStorage.removeItem("stepIndex");
          localStorage.removeItem("writeId");
          localStorage.removeItem("allFeatures");
          if (route.path === "/new") {
            router.replace({ path: "/new" });
            Bus.$emit("pageChange", 1);
          } else {
            router.push({ path: "/new" });
          }
        }
      }
    }
  });
};

const createNew = () => {
  getUserInfo(true);
};

const changePassword = () => {
  if (upPassFormRef.value) {
    upPassFormRef.value.resetFields();
  }
  upPassForm.oldPassword = "";
  upPassForm.newPassword = "";
  upPassVisible.value = true;
};

const doChangePassword = () => {
  upPassFormRef.value.validate(valid => {
    let msg = "请填写密码！";
    // 不通过校验
    if (!valid) return ElMessage.error(msg);
    // 通过校验
    btnLoading.value = true;
    let params = {
      oldPassword: upPassForm.oldPassword,
      newPassword: upPassForm.newPassword
    };
    ssoUpdatePassword(params)
      .then(res => {
        btnLoading.value = false;
        upPassVisible.value = false;
        removeCookie();
        store.commit("updateUserInfo", {});
        router.replace({ path: "/login" });
      })
      .catch(e => {
        btnLoading.value = false;
        // console.log(e)
      });
  });
};

const changePhone = () => {
  if (upPhoneFormRef.value) {
    upPhoneFormRef.value.resetFields();
  }
  upPhoneForm.password = "";
  upPhoneForm.telephone = "";
  upPhoneForm.authCode = "";
  upPhoneVisible.value = true;
};

const getCode = () => {
  let phone = upPhoneForm.telephone;
  if (!phone.trim()) {
    ElMessage.error("请输入手机号");
    upPhoneFormRef.value.validateField("telephone");
    return;
  }
  smsGetCode({ phone }).then(res => {
    showText.value = false;
    let interval = setInterval(() => {
      let times = --second.value;
      second.value = times < 10 ? "0" + times : times;
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      second.value = 60;
      showText.value = true;
    }, 60000);
  });
};

const doChangePhone = () => {
  upPhoneFormRef.value.validate(valid => {
    // 不通过校验
    if (!valid) return;
    // 通过校验
    btnLoading.value = true;
    let params = {
      password: upPhoneForm.password,
      telephone: upPhoneForm.telephone,
      authCode: upPhoneForm.authCode
    };
    ssoUpdatePhone(params)
      .then(res => {
        btnLoading.value = false;
        upPhoneVisible.value = false;
        ElMessage.success(res.message);
        getUserInfo1();
      })
      .catch(e => {
        btnLoading.value = false;
        // console.log(e)
      });
  });
};

const charge = () => {
  store.commit("authType", 2);
  store.commit("updateEquitiesName1", '')
  store.commit("updateChargeType", 0);
  store.commit("updateAuth", true);
};

const getData = () => {
  consumption().then(res => {
    infoMsg.value = res.data;
  });
};

const openWindow = path => {
  let baseUrl = window.location.origin;
  let pathArr = window.location.pathname.split("/");
  pathArr.splice(pathArr.length - 1, 1, path);
  let url = baseUrl + pathArr.join("/");
  window.open(url, "_blank");
};

const toAuth = authStatus => {
  if (authStatus === 5) {
    openWindow("#/auth?type=1");
  } else if (authStatus === 3 || authStatus === 7) {
    store.commit("authType", 1);
    store.commit("updateAuth", true);
  } else {
    openWindow("#/auth?type=0");
  }
};

const balanceDetail = () => {
  router.push({
    path: "/balance"
  });
};

const packageDetail = item => {
  if (route.path === "/package") {
    router.push({
      path: "/package",
      query: {
        id: item.id
      }
    });
    Bus.$emit("packageChange", item.id);
  } else {
    router.push({
      path: "/package",
      query: {
        id: item.id
      }
    });
  }
};

const packageListDetail = () => {
  router.push({
    path: "/package-list"
  });
};

const infoDetail = reactive({
  data: {}
});

const infoClick = () => {
  localStorage.setItem("noticeDetail", JSON.stringify(infoDetail.data));
  if (route.path === "/noticeDetail") {
    router.push({ path: "/noticeDetail" });
    Bus.$emit("noticeDetailChange", "1");
  } else {
    router.push({ path: "/noticeDetail" });
  }
};

const moreClick = () => {
  if (route.path === "/notice") {
    router.push({ path: "/notice" });
    Bus.$emit("noticeChange", "1");
  } else {
    router.push({ path: "/notice" });
  }
};

const options = reactive({
  text: "", // 通知内容
  size: "12px", // 通知内容文字大小（默认16px）
  color: "#666666", // 通知内容文字颜色(默认#f60)
  backround: "#fff", //背景颜色(默认#fff7cc)
  delay: "1000", // 动画延迟时间(默认一秒后开始滚动，单位毫秒)
  speed: "50" // 滚动速率默认50 (px/s)
});

const getNotice = () => {
  bannerList({
    number: 1,
    platform: 1
  }).then(res => {
    if (res.data && res.data.length) {
      options.text = res.data[0].title;
      infoDetail.data = res.data[0];
    }
  });
};

const domainInfo = computed({
  get() {
    return store.state.domainInfo;
  },
  set(val) {
    store.commit("updateDomainInfo", val);
  }
});

const getDomain = () => {
  agentDomain().then(res => {
    console.log(res);
    const currentDate = new Date();
    let year = currentDate.getFullYear();  // 返回当前年份
    if (res.data) {
      domainInfo.value = {
        copyrightInfo: res.data.copyrightInfoStart !== '' && res.data.copyrightInfoEnd !== '' ? res.data.copyrightInfo : '',
        copyrightInfoStart: res.data.copyrightInfoStart,
        copyrightInfoEnd: res.data.copyrightInfoEnd,
        domain: res.data.domain,
        icpNumber: res.data.icpNumber,
        publicSecurityNumber: res.data.publicSecurityNumber,
        systemName: res.data.systemName || '软著登记服务平台',
        icon: res.data.icon,
        subordinateAccountPaySetting: res.data.subordinateAccountPaySetting,
        status: res.data.status
      }
    } else {
      domainInfo.value = {
        copyrightInfo: 'Copyright@2016-' + year + '&nbsp;&nbsp;安徽高山科技有限公司&nbsp;&nbsp;版权所有',
        icpNumber: '皖ICP备17001294号',
        publicSecurityNumber: '皖公网安备 34019202000669号',
        systemName: '软著登记服务平台',
      }
    }
    store.commit('updateDomainInfo', domainInfo.value)
    document.title = domainInfo.value.systemName
  })
}

const equitiesList = ref([])

 const getPackageEquities = () => {
    systemConfig("PACKAGE_EQUITIES").then(res => {
      if (res && res.data) {
        equitiesList.value = JSON.parse(res.data)
        // store.commit("updateSingleCharge", res.data);
      }
    });
  };

  const muitEquities = (list) => {
    let arr = []
    list.forEach(e => {
      let equities = JSON.parse(e.equities)
      if (equities.length > 1) {
        arr.push(
          {
             name: e.packageName,
             equities: equities
          }
        )
      }
    });
    return arr
  }

const showPayloadding = ref(false)

const feedback = () => {
  store.commit('updateFeedback', true)
}

onMounted(() => {
  // getData()
  showPayloadding.value = false
  getDomain();
  getUserInfo();
  
  getSystemConfig();
  Bus.$off("payLoad");
  Bus.$on("payLoad", e => {
    showPayloadding.value = e
  });
});

onUnmounted(() => {
  Bus.$off("payLoad");
});

watchEffect(() => {
  getNotice();
  updateActiveMenuIndex(route.path);
});
</script>

<template>
  <div class="layout">
    <el-container>
      <el-header class="header all-header">
        <div class="header-nav flex-between">
          <div class="home-title">{{domainInfo.systemName || '软著登记服务平台'}}</div>
          <div class="title flex-start">
            <!-- // 企业认证状态:0->未通过；1->企业认证通过;2->代理人商认证通过；3->企业认证审核中；4->代理人商认证审核中 5个人状态通过 -->
            <!-- 6 个人认证审核中  7 个人认证成功但是企业认证中 -->
            <span
              @click="toAuth(userInfo.authStatus)"
              v-if="
                userInfo.authStatus * 1 === 0 || userInfo.authStatus * 1 === 3
              "
              style="color: #666666;cursor: pointer;"
            >
              {{ userInfo.companyName + "(未认证)" }}
            </span>
            <span
              @click="toAuth(userInfo.authStatus)"
              v-else-if="userInfo.authStatus * 1 === 7"
              style="color: #666666;cursor: pointer;"
            >
              {{ userInfo.companyName + "(企业认证审核中)" }}
            </span>
            <span v-else>{{ userInfo.companyName }}</span>
            <span
              @click="toAuth(userInfo.authStatus)"
              class="up"
              v-if="userInfo.authStatus * 1 === 5"
              ><img src="../assets/image/up.png" alt="" />升级为企业</span
            >
            <!-- <span class="balance">余额</span>
            <span class="balance-value">{{ amount.toFixed(2) }}</span> -->
            <!-- <span @click="balanceDetail" v-if="userInfo.isAdmin || !userInfo.companyId" class="balance2">明细></span> -->
            <span class="balance">套餐剩余</span>
            <div class="balance-all">
              <div class="balance-item" v-for="(item, index) in equitiesList" :key="index">
                <el-tooltip
                  effect="dark"
                  :disabled="!muitEquities(userInfo.packageMap[item.equities]).length"
                  placement="bottom">
                  <template #content>
                    <div style="width:200px">
                      <div v-for="(it, i) in muitEquities(userInfo.packageMap[item.equities])" :key="i">
                        <span>{{'[' + it.name + ']中含'}}</span>
                        <span v-for="(c, ci) in it.equities" :key="ci">
                          {{'[' + c.name + ']'}}<span v-if="ci !== it.equities.length - 1">、</span>
                        </span>
                        <span>权益</span>
                      </div>
                    </div>
                  </template>
                  <div>
                    <div v-if="userInfo.packageMap[item.equities].length" class="balance-num">
                      {{ userInfo.packageMap[item.equities].reduce((acc, cur) => acc + cur.balance, 0) }}
                      <span v-if="muitEquities(userInfo.packageMap[item.equities]).length" style="display:flex;align-items:center;justify-content: center;color:#ff8f1f;margin-left:2px">
                        <el-icon><Warning /></el-icon>
                      </span>
                    </div>
                    <div v-else class="balance-num">0</div>
                    <div class="balance-text">{{item.name}}</div>
                  </div>
                </el-tooltip>
              </div>
              <span v-if="(userInfo.isAdmin || !userInfo.companyId)" @click="packageListDetail" class="balance1">更多></span>
            </div>
            <!-- <span v-if="packageList.length" class="balance-value1">
              {{ packageList.reduce((acc, cur) => acc + cur.balance, 0) }}
            </span>
            <span v-else class="balance-value1">0</span> -->
            <!-- <span class="balance-value2" v-if="packageList.length">{{
              "(" + packageList[0].packageName + ")"
            }}</span> -->
            <div
              v-if="
                userInfo.paySetting * 1 === 1 &&
                  (userInfo.isAdmin || !userInfo.companyId)
              "
              class="charge"
              @click="charge"
            >
              购买套餐
            </div>
          </div>
          <div class="info">
            <!-- <noticebar :options="options" :moreClick="moreClick" :infoClick="infoClick"></noticebar> -->
            <!-- <div class="info-create" @click="createNew">生成软件</div> -->
            <div class="info-icon">
              <img
                v-if="userInfo && userInfo.icon"
                :src="userInfo.icon"
                alt=""
              />
              <el-icon v-else :size="14" color="#FFF"><UserFilled /></el-icon>
            </div>
            <el-dropdown style="z-index:10002" @visible-change="handleVisible">
              <div class="el-dropdown-link flex-end">
                <div class="account">{{ userInfo.username }}</div>
                <el-icon v-if="menuVisible" :size="12" class="el-icon--right">
                  <arrow-up />
                </el-icon>
                <el-icon v-else :size="12" class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="changePassword"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="!userInfo.phone"
                    @click="changePhone"
                    >{{
                      userInfo.phone ? "换绑手机" : "设置手机号"
                    }}</el-dropdown-item
                  >
                  <el-dropdown-item @click="logout">安全退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside width="208px" class="left-menu">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-vertical-demo"
            :router="true"
            @open="handleOpen"
            background-color="transparent"
            text-color="#FFFFFF"
            active-text-color="#6196FF"
            @close="handleClose"
            @select="select"
          >
            <el-sub-menu index="/">
              <template #title>
                <div>软著登记服务</div>
              </template>
              <el-menu-item index="/home">
                <template #title>
                  <div>登记列表</div>
                </template>
              </el-menu-item>
              <el-menu-item index="/correction-list">
                <template #title>
                  <div>补正记录</div>
                </template>
              </el-menu-item>
               <el-menu-item index="/todo">
                <template #title>
                  <div>待办事项</div>
                </template>
              </el-menu-item>
            </el-sub-menu>
            <!-- <el-menu-item index="/home">
              <template #title>
                <div>软著登记</div>
              </template>
            </el-menu-item> -->
            <!-- <el-menu-item index="/results">
              <template #title>
                <div>即将出结果</div>
              </template>
            </el-menu-item> -->
            <!-- <el-menu-item index="/correction-list" v-if="userInfo && (userInfo.companyId * 1 === 13 || userInfo.companyId * 1 === 43)">
              <template #title>
                <div>补正记录</div>
              </template>
            </el-menu-item> -->
            <!-- <el-menu-item index="/correction-list">
              <template #title>
                <div>补正记录</div>
              </template>
            </el-menu-item> -->
            <el-menu-item index="/applicant">
              <template #title>
                <div>著作权人库</div>
              </template>
            </el-menu-item>
            <!-- <el-menu-item index="/todo">
              <template #title>
                <div>待办事项</div>
              </template>
            </el-menu-item> -->
             <el-menu-item index="/aftersale">
              <template #title>
                <div>售后管理</div>
              </template>
            </el-menu-item>
            <el-sub-menu index="/setting">
              <template #title>
                <div>企业管理</div>
              </template>
              <el-menu-item index="/setting">
                <template #title>
                  <div>企业设置</div>
                </template>
              </el-menu-item>
              <el-menu-item v-if="userInfo.isAdmin || !userInfo.companyId" index="/company">
                <template #title>
                  <div>开票信息</div>
                </template>
              </el-menu-item>
              <el-menu-item v-if="userInfo.isAdmin" index="/member">
                <template #title>
                  <div>成员管理</div>
                </template>
              </el-menu-item>
            </el-sub-menu>
            <el-menu-item v-if="userInfo.isAdmin || !userInfo.companyId" index="/record">
              <template #title>
                <div>订单记录</div>
              </template>
            </el-menu-item>
             <el-menu-item index="/notice">
              <template #title>
                <div>通知公告</div>
              </template>
            </el-menu-item>
            <!-- <el-menu-item index="/address">
              <template #title>
                <div>联系地址管理</div>
              </template>
            </el-menu-item>
            <el-menu-item v-if="userInfo.isAdmin" index="/member">
              <template #title>
                <div>成员管理</div>
              </template>
            </el-menu-item>
            <el-menu-item v-if="userInfo.isAdmin || !userInfo.companyId" index="/record">
              <template #title>
                <div>订单记录</div>
              </template>
            </el-menu-item>
            <el-menu-item index="/aftersale">
              <template #title>
                <div>我的售后</div>
              </template>
            </el-menu-item>
            <el-menu-item v-if="userInfo.isAdmin || !userInfo.companyId" index="/company">
              <template #title>
                <div>开票信息</div>
              </template>
            </el-menu-item>
            <el-menu-item index="/notice">
              <template #title>
                <div>通知公告</div>
              </template>
            </el-menu-item> -->
          </el-menu>
          <div class="aside-footer">
            <div class="package-tips" v-if="isGetUser && userInfo && userInfo.packageMap['CREATE_WRITE'] &&
              (userInfo.packageMap['CREATE_WRITE'].reduce((acc, cur) => acc + cur.balance, 0)/userInfo.packageMap['CREATE_WRITE'].reduce((acc, cur) => acc + cur.originalBalance, 0) < 0.1)">
              您的套餐可用余额仅剩下【{{ userInfo.packageMap['CREATE_WRITE'].reduce((acc, cur) => acc + cur.balance, 0) }}】件，
              为了不影响您正常的使用，请及时充值
            </div>
            版本V2.2
            <div style="color:#6196ff;cursor: pointer;margin-top: 2px;" @click="feedback">意见反馈</div>
          </div>
        </el-aside>
        <div class="main-box">
          <el-main id="main">
            <router-view v-slot="{ Component, route }">
              <keep-alive
                :exclude="[
                  'submitResult',
                  'createApplicant',
                  'confirm',
                  'correction',
                  'orderDetail',
                  'addApplicant',
                  'printingMaterials',
                  'development',
                  'new',
                  'protect',
                  'editor',
                  'auth',
                  'balance',
                  'package',
                  'packageList',
                  'apply',
                  'noticeDetail'
                ]"
              >
                <component :is="Component" :key="route.name"></component>
              </keep-alive>
            </router-view>
          </el-main>
        </div>
      </el-container>
    </el-container>
    <el-dialog v-model="upPassVisible" title="修改密码">
      <el-form :model="upPassForm" :rules="formRules" ref="upPassFormRef">
        <el-form-item
          label="旧密码"
          prop="oldPassword"
          :label-width="formLabelWidth"
        >
          <el-input
            type="password"
            show-password
            placeholder="请输入旧密码"
            v-model="upPassForm.oldPassword"
            :disabled="isEdit"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="newPassword"
          :label-width="formLabelWidth"
        >
          <el-input
            type="password"
            show-password
            placeholder="设置6位及以上字母+数字组合的密码"
            v-model="upPassForm.newPassword"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            class="btn-item btn-active"
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="doChangePassword"
          >
            确定
          </el-button>
          <div class="btn-item" @click="upPassVisible = false">取消</div>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      style="width: 490px"
      v-model="upPhoneVisible"
      :title="userInfo.phone ? '修改绑定手机' : '设置手机号'">
      <el-form :model="upPhoneForm" :rules="formRules" ref="upPhoneFormRef">
        <el-form-item
          v-if="userInfo.phone"
          label="原手机号"
          :label-width="formLabelWidth"
        >
          <div>{{ userInfo.phone }}</div>
        </el-form-item>
        <el-form-item
          label="密码"
          v-if="userInfo.phone"
          prop="password"
          :label-width="formLabelWidth"
        >
          <el-input
            type="password"
            show-password
            placeholder="请输入登录密码"
            v-model="upPhoneForm.password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          v-if="userInfo.phone"
          label="新手机号"
          class="phone-code"
          prop="telephone"
          :label-width="formLabelWidth"
        >
          <el-input
            oninput="value=value.replace(/\D/g, '')"
            v-model="upPhoneForm.telephone"
            placeholder="请输入新手机号码"
            :maxlength="11"
            autocomplete="off"
          />
          <el-button
            class="code-btn"
            @click="getCode"
            link
            :disabled="!showText"
            >{{ showText ? "获取验证码" : second + "s后重新获取" }}</el-button
          >
        </el-form-item>
        <el-form-item
          v-else
          label="手机号"
          class="phone-code"
          prop="telephone"
          :label-width="formLabelWidth"
        >
          <el-input
            oninput="value=value.replace(/\D/g, '')"
            v-model="upPhoneForm.telephone"
            placeholder="请输入手机号码"
            :maxlength="11"
            autocomplete="off"
          />
          <el-button
            class="code-btn"
            @click="getCode"
            link
            :disabled="!showText"
            >{{ showText ? "获取验证码" : second + "s后重新获取" }}</el-button
          >
        </el-form-item>
        <el-form-item
          label="验证码"
          prop="authCode"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="upPhoneForm.authCode"
            placeholder="请输入短信验证码"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            class="btn-item btn-active"
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="doChangePhone"
          >
            确定
          </el-button>
          <div class="btn-item" @click="upPhoneVisible = false">取消</div>
        </span>
      </template>
    </el-dialog>
    <PayLoadding v-model="showPayloadding"></PayLoadding>
  </div>
</template>

<style scoped lang="less">
.layout {
  .phone-code {
    .code-btn {
      position: absolute;
      right: 16px;
      color: #6196ff;
    }
  }
  :deep(.el-dialog) {
    width: 400px;
  }
  :deep(.el-dialog__header) {
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .add_submit {
      width: 100px;
      height: 31px;
      line-height: 31px;
      background: #6196ff;
      border-radius: 50px;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
    .add_cancel {
      width: 100px;
      height: 29px;
      line-height: 29px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 1px solid #6196ff;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #6196ff;
      margin-left: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
  .el-header {
    padding: 0;
    height: 50px !important;
    position: fixed;
    width: 100%;
    z-index: 10000;
  }
  .el-aside {
    top: 50px;
    background: #f7f7f7;
    position: fixed;
    height: calc(100vh - 50px);
    z-index: 99;
    .aside-footer {
      position: absolute;
      width: 100%;
      bottom: 20px;
      color: #333;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .package-tips {
        margin: 16px;
        border: 1px solid red;
        color: red;
        padding: 16px;
        border-radius: 5px;
        line-height: 20px;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .el-menu {
    padding-top: 48px;
    border-right: none;
  }
  :deep(.el-sub-menu) {
    font-size: 12px;
    color: #333333;
    .el-sub-menu__title {
      font-size: 12px !important;
      color: #333333 !important;
      display: flex;
      justify-content: flex-start;
      padding: 0 16px 0 40px !important;
    }
    .el-menu-item {
      background-color: #FFF !important;
    }
    .el-menu-item.is-active {
      background-color: #FFF !important;
      color: #1a1a1a !important;
      font-weight: bold;
    }

    .el-menu-item:hover {
      background-color: #FFF !important;
      color: #1a1a1a !important;
      font-weight: bold;
    }
  }
  :deep(.el-sub-menu.is-active) {
    .el-sub-menu__title {
      background-color: #e6e6e6 !important;
      color: #1a1a1a !important;
      font-weight: bold;
    }
  }
  .el-menu-item {
    width: 208px !important;
    height: 40px !important;
    line-height: 40px !important;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    color: #333333;
    display: flex;
    justify-content: flex-start;
    padding: 0 16px 0 40px !important;
  }

  .el-menu-item.is-active {
    background-color: #e6e6e6 !important;
    color: #1a1a1a !important;
    font-weight: bold;
  }

  .el-menu-item:hover {
    background-color: #e6e6e6 !important;
    color: #1a1a1a !important;
    font-weight: bold;
  }
  .header {
    .header-nav {
      padding: 0px 40px;
      height: 50px;
      background: #ffffff;
      color: #666666;
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
      font-size: 12px;
      font-family: -apple-system, BlinkMacSystemFont, "PingFang SC",
        "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 400;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      :deep(:focus-visible) {
        outline: none !important;
      }
      :deep(.el-dropdown) {
        color: inherit;
      }
      .info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .el-dropdown-link {
          cursor: pointer;
        }
        .account {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          margin: 0 8px 0 8px;
        }
        .info-create {
          width: 133px;
          height: 39px;
          border-radius: 100px;
          opacity: 1;
          border: 1px solid #6196ff;
          font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          color: #6196ff;
          box-sizing: border-box;
          cursor: pointer;
          margin-right: 58px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .info-icon {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: #6196ff;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
      }
      .home-title {
        // cursor: pointer;
        color: #333333;
        font-weight: bold;
        width: 168px;
        text-align: left;
      }
      .title {
        flex: 1;
        font-weight: bold;
        .balance {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          margin: 0 8px 0 24px;
        }
        .up {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          color: #6196ff;
          cursor: pointer;
          img {
            margin: 0 6px 0 10px;
          }
        }
        .balance-value {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          color: #6196ff;
        }
        .balance-all {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: #F9F9F9;
          padding: 2px 24px;
          .balance-item + .balance-item {
            margin-left: 16px;
          }
          .balance-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .balance-num {
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
              color: #6196ff;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .balance-text {
              font-size: 12px;
              color: #999;
              font-weight: 400;
            }
          }
        }
        .balance-value1 {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          color: #6196ff;
          margin-right: 8px;
        }
        .balance-value2 {
          // font-size: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          margin-right: 8px;
        }
        .balance1 {
          margin-left: 16px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          cursor: pointer;
        }
        .balance2 {
          margin-left: 8px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          cursor: pointer;
        }
        .charge {
          margin-left: 16px;
          width: 64px;
          height: 19px;
          line-height: 19px;
          background: #ff8f1f;
          border-radius: 100px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
          margin-right: 17px;
          cursor: pointer;
        }
        .fee-info {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          span {
            margin-left: 4px;
          }
        }
      }
      .logout {
        cursor: pointer;
      }
    }
  }
  .main-box {
    position: absolute;
    height: calc(100vh - 50px);
    width: calc(100vw - 208px);
    top: 50px;
    left: 208px;
    // overflow: hidden;
  }
  .el-main {
    // overflow: auto;
    // margin-left: 208px;
    // margin-top: 50px;
    padding: 0;
    margin: 0 24px;
    overflow: unset;
    height: calc(100vh - 50px);
    background: #ffffff;
  }
}
.package-body {
  max-height: 800px;
  overflow-y: auto;
  padding-right: 12px;
}
.package-pop {
  padding-bottom: 8px;
  .package-title {
    height: 23px;
    font-size: 12px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 23px;
  }
  .package-item {
    height: 19px;
    font-size: 12px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    color: #767676;
    line-height: 19px;
    .item-value {
      color: #6196ff;
      margin-left: 16px;
    }
    .package-detail {
      color: #6196ff;
      cursor: pointer;
    }
  }
}
.package-pop + .package-pop {
  border-top: 1px solid #d8d8d8;
  padding-top: 8px;
}
</style>
